import { useSelector, useDispatch } from 'react-redux'
import api from '../../api'
import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

import {
    setMatters,
    setClientName,
    setMatterNumbers,
    setMatterNumber,
    setClients,
    setDefendants,
    setFilteredClients,
    setFilteredMatters,
    setTitle,
    setTitle2,
    setSelectedCategory,
    setKey,
    setAllday,
    setDate,
    setDaterange,
    setNotes,
    setFileIds,
    setUploadedFiles,
    setCreateTask,
    setTimeZone,
    setLocation
} from '../../store/CalendarInput/action'
import { AutoComplete } from 'primereact/autocomplete'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'

const PrimaryInfo = ({clientNameRef, matterNumberRef}) => {
    const dispatch = useDispatch()
    const matters = useSelector(state => state.calendarInput.matters)
    const matterNumbers = useSelector(state => state.calendarInput.matterNumbers)
    const clients = useSelector(state => state.calendarInput.clients)
    const defendants = useSelector(state => state.calendarInput.defendants)
    const currentlyEditingEvent = useSelector(state => state.calendarInput.currentlyEditingEvent)
    const categories = useSelector(state => state.calendarInput.categories)
    const selectedCategory = useSelector(state => state.calendarInput.selectedCategory)
    const clientName = useSelector(state => state.calendarInput.clientName)
    const matterNumber = useSelector(state => state.calendarInput.matterNumber)
    const title = useSelector(state => state.calendarInput.title)
    const title2 = useSelector(state => state.calendarInput.title2)

    const filteredClients = useSelector(state => state.calendarInput.filteredClients)
    const filteredMatters = useSelector(state => state.calendarInput.filteredMatters)
    const location = useSelector(state => state.calendarInput.location)
    const [isFocusedOnClientName, setIsFocusedOnClientName] = useState(false)
    const [isFocusedOnMatterNumber, setIsFocusedOnMatterNumber] = useState(false)

    useEffect(() => {
        document.title = "Calendar Input"
        axios.all([
            api.get('matters'),
            api.get('clients'),
            api.get('defendants')
        ])
            .then(axios.spread((mattersResponse, clientsResponse, defendantsResponse) => {
                const matterNumbers = mattersResponse.data.map(matter => matter.MatterNumber)
                const formattedClients = clientsResponse.data.map(client => client.ClientName)
                dispatch(setMatters(mattersResponse.data))
                dispatch(setMatterNumbers(matterNumbers))
                dispatch(setClients(formattedClients))
                dispatch(setDefendants(defendantsResponse.data))
                console.log('dispatched matters, matterNumbers, clients, defendants')
            }))
            .catch(error => {
                console.log(error)
            })
    }, [])

    useEffect(() => {
        console.log('Matters:', matters)
        console.log('Clients:', clients)
        console.log('Defendants:', defendants)
        console.log('Matter Numbers:', matterNumbers)
    }, [matters, clients, defendants, matterNumbers])

    const findMatchingClientName = (mn) => {
        console.log('Finding matching client name...')
        console.log('matterNumberRef.current:', matterNumberRef.current)
        api.get(`find_matching_client_and_defendant/${mn || matterNumberRef.current}`)
            .then(response => {
                console.log('response:', response)
                const { _clientName, _defendantNames, _customCalendarTitle } = response.data
                if (_clientName) {
                    dispatch(setClientName(_clientName))
                }
                if (_defendantNames) {
                    if (_customCalendarTitle) {
                        dispatch(setTitle(_customCalendarTitle))
                        console.log('Setting title:', _customCalendarTitle)
                    } else {
                        const nameParts = _clientName.replace(/'/g, '').split(" ")
                        let lastNameLettersFromMatter = matterNumberRef.current.substr(1, 3).toUpperCase()
                        const matchingIndex = nameParts.findIndex(part => part.toUpperCase().startsWith(lastNameLettersFromMatter))

                        // Extract everything from the matching index onwards to form the last name
                        const clientLastName = nameParts.slice(matchingIndex).join(" ")
                        const defendant_names_string = _defendantNames.join(', ')
                        const t = `${clientLastName} v. ${defendant_names_string}`
                        console.log('Setting title:', t)
                        dispatch(setTitle(t))
                    }
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    const findMatchingMatterNumber = () => {
        api.get(`find_matching_matter_and_defendant/${clientNameRef.current}`)
            .then(response => {
                console.log('response:', response)
                const { _matterNumber, _defendantNames, _customCalendarTitle } = response.data
                if (_matterNumber) {
                    if (_matterNumber !== matterNumberRef.current) {
                        dispatch(setMatterNumber(_matterNumber))
                        if (_customCalendarTitle) {
                            dispatch(setTitle(_customCalendarTitle))
                            console.log('Setting title:', _customCalendarTitle)
                        } else if (_defendantNames) {
                            const nameParts = clientNameRef.current.replace(/'/g, '').split(" ")
                            let lastNameLettersFromMatter = _matterNumber.substr(1, 3).toUpperCase()
                            const matchingIndex = nameParts.findIndex(part => part.toUpperCase().startsWith(lastNameLettersFromMatter))

                            // Extract everything from the matching index onwards to form the last name
                            const clientLastName = nameParts.slice(matchingIndex).join(" ")
                            const defendant_names_string = _defendantNames.join(', ')
                            const t = `${clientLastName} v. ${defendant_names_string}`
                            console.log('Setting title:', t)
                            dispatch(setTitle(t))
                        }
                    }
                }
            })
            .catch(error => {
                console.error(error)
            })
    }

    useEffect(() => {
        if (clients?.includes(clientName) && !isFocusedOnMatterNumber) {
            clientNameRef.current = clientName
            findMatchingMatterNumber()
        }
    }, [clientName])

    useEffect(() => {
        if (matterNumbers?.includes(matterNumber) && !isFocusedOnClientName) {
            matterNumberRef.current = matterNumber
            findMatchingClientName(matterNumber)
        }
    }, [matterNumber])

    const handleClientSearch = event => {
        let results = clients.filter(client =>
            client?.toLowerCase().includes(event.query.toLowerCase())
        )
        dispatch(setFilteredClients(results))

        clientNameRef.current = results[0]
        findMatchingMatterNumber()
    }

    const handleMatterSearch = (event) => {
        let results = matterNumbers.filter((matterNumber) =>
            matterNumber?.toLowerCase().startsWith(event.query.toLowerCase())
        )
        dispatch(setFilteredMatters(results))
        console.log('results:', results)

        matterNumberRef.current = results[0]?.MatterNumber
        findMatchingClientName()
    }

    useEffect(() => {
        if (currentlyEditingEvent) {
            const { summary, description, colorId, start, end, attachments, location } = currentlyEditingEvent

            const titleParts = summary.split(' - ')
            if (titleParts.length > 1) {
                dispatch(setTitle(titleParts[0]))
                dispatch(setTitle2(titleParts[1]))
            } else {
                dispatch(setTitle(summary))
            }

            dispatch(setLocation(location))

            try {
                const descriptionParts = (description || summary).split('\n\n')
                if (descriptionParts.length > 1) {
                    dispatch(setMatterNumber(descriptionParts[0]))
                    dispatch(setNotes(descriptionParts[1]))
                } else {
                    dispatch(setNotes(description))
                }

            handleMatterSearch({ query: descriptionParts[0] })

            } catch (error) {
                console.error(error)
            }

            // Get category from mapping
            dispatch(setSelectedCategory(categories.find(category => category.colorId === parseInt(colorId))))
            dispatch(setAllday(!start.dateTime))
            dispatch(setDate(start.date || start.dateTime.split('T')[0]))
            dispatch(setDaterange([
                (new Date(start.dateTime || start.date) || new Date()).toISOString(),
                (new Date(end.dateTime || start.date) || new Date()).toISOString()
            ]))
            // setTimeZone({ value: start.timeZone || 'America/Los_Angeles' })
            dispatch(setTimeZone(start.timeZone || 'America/Los_Angeles'))
            console.log('start.timeZone:', start.timeZone)


            dispatch(setFileIds(attachments?.map(attachment => attachment.fileId)))
            dispatch(setUploadedFiles(attachments?.map(attachment => {
                return {
                    name: attachment.title,
                    objectURL: attachment.iconLink
                }
            })))
        }

        dispatch(setCreateTask(false))

        dispatch(setKey(Math.random().toString()))
    }, [currentlyEditingEvent])

    const categoryOptionTemplate = (option) => {
        return (
            <div className={`flex align-items-center`}>
                <span className={`inline-flex justify-content-center align-items-center mr-3 w-2rem h-2rem border-circle border-1 border-${option.color}-200 bg-${option.color}-100`}>
                    <i className={`pi pi-calendar text-${option.color}-700`}></i>
                </span>
                <div>{option.name}</div>
            </div>
        )
    }

    return (
        <>
            <div className="field mb-4 col-12 md:col-4">
                <label htmlFor="client_name" className="font-medium text-900">Client Name</label>
                <AutoComplete
                    id="client_name"
                    value={clientName}
                    suggestions={filteredClients}
                    completeMethod={handleClientSearch}
                    onChange={(e) => {
                        dispatch(setClientName(e.value))
                    }}
                    onFocus={() => setIsFocusedOnClientName(true)}
                    onBlur={() => setIsFocusedOnClientName(false)}
                    placeholder='Client Name'
                />
            </div>

            <div className="field mb-4 col-12 md:col-4">
                <label htmlFor="matter_number" className="font-medium text-900">Matter Number</label>
                <AutoComplete
                    id="matter_number"
                    value={matterNumber}
                    suggestions={filteredMatters}
                    completeMethod={handleMatterSearch}
                    onChange={(e) => {
                        dispatch(setMatterNumber(e.value))
                    }}
                    onFocus={() => setIsFocusedOnMatterNumber(true)}
                    onBlur={() => setIsFocusedOnMatterNumber(false)}
                    placeholder='Matter Number'
                />
            </div>
            <div className="field mb-4 col-12 md:col-4">
                <label htmlFor="location" className="font-medium text-900">Location</label>
                <InputText id="location" type="text" value={location} onChange={(e) => dispatch(setLocation(e.target?.value))}
                    placeholder='Location' />
            </div>
            <div className="field mb-4 col-6 md:col-4">
                <label htmlFor="title" className="font-medium text-900">Title</label>
                <InputText id="title" type="text" value={title} onChange={(e) => dispatch(setTitle(e.target?.value))}
                    placeholder='Title before dash' />
            </div>
            <div className="field mb-4 col-6 md:col-4">
                <label htmlFor="title2" className="font-medium text-900">Title 2</label>
                <InputText id="title2" type="text2" value={title2} onChange={(e) => dispatch(setTitle2(e.target?.value))}
                    placeholder='Title after dash' />
            </div>
            <div className="field mb-4 col-12 md:col-4">
                <label htmlFor="customer_email" className="font-medium text-900">Category</label>
                <Dropdown value={selectedCategory} onChange={(e) => dispatch(setSelectedCategory(e.value))} options={categories} optionLabel="name"
                    placeholder="Select a Category" itemTemplate={categoryOptionTemplate} className="custom-dropdown" />
            </div>
        </>
    )
}

export default PrimaryInfo