import { configureStore } from '@reduxjs/toolkit';
import calendarInputReducer from './CalendarInput/reducer'
import caseInfoReducer from './CaseDetails/reducer'
import appReducer from './App/reducer';

const store = configureStore({
    reducer: {
      calendarInput: calendarInputReducer,
      caseDetails: caseInfoReducer,
      app: appReducer,
    },
  });

export default store
