import {
    SET_LOADING,
    SET_DOC_CHATS,
    ADD_DOC_CHAT
} from './action';

const initialState = {
    loading: false,
    docChats: []
}

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING:
            return { ...state, loading: action.payload };
        case SET_DOC_CHATS:
            return { ...state, docChats: action.payload };
        case ADD_DOC_CHAT:
            return { ...state, docChats: [...state.docChats, action.payload] };
        default:
            return state;
    }
}

export default appReducer;