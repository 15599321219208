import api from "../../api";

export const SET_LOADING = 'SET_LOADING';
export const SET_DOC_CHATS = 'SET_DOC_CHATS';
export const ADD_DOC_CHAT = 'ADD_DOC_CHAT';

export const setLoading = loading => ({ type: SET_LOADING, payload: loading });
export const setDocChats = docChats => ({ type: SET_DOC_CHATS, payload: docChats });
export const newDocChat = () => {
    return (dispatch) => {
        api.post('/document_chat').then(response => {
            const { chats } = response.data;
            dispatch(setDocChats(chats));
        }
        ).catch(error => {
            console.error(error);
        })
    }
}