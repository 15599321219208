import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { PrimeReactProvider } from 'primereact/api';
import store from './store/store'

// import "primereact/resources/primereact.css";

import "primeflex/primeflex.css";
//core
import "primereact/resources/primereact.min.css";
//icons
import 'primeicons/primeicons.css';
//theme
import "primereact/resources/themes/lara-light-blue/theme.css";
import './App.css'
import "primereact/resources/primereact.min.css";

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <PrimeReactProvider>
        <App />
      </PrimeReactProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
